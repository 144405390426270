import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from './components/atoms/Loading';
import fetchCollection from './utils/utils';
import { PageEntry } from './types/types';

const getLazyComponent = (containerName: string) => {
	switch (containerName) {
		case 'HomePage':
			return import(/* webpackChunkName: "home" */ './pages/Home');
		case 'WebPresencePage':
			return import(/* webpackChunkName: "web-presence" */ './pages/WebPresence');
		case 'MyWorkPage':
			return import(/* webpackChunkName: "my-work" */ './pages/MyWork');
		case 'MyWorkDetail':
			return import(/* webpackChunkName: "work-detail" */ './pages/MyWorkDetail');
		default:
			return Promise.reject('No container with specified name found!');
	}
}

const App: React.FC = () => {
	const [pageEntries, setPageEntries] = useState<PageEntry[] | null>(null);

	const HomePage = lazy(() => getLazyComponent('HomePage'))
	const WebPresencePage = lazy(() => getLazyComponent('WebPresencePage'))
	const MyWorkPage = lazy(() => getLazyComponent('MyWorkPage'))
	const MyWorkDetail = lazy(() => getLazyComponent('MyWorkDetail'))

	useEffect(() => {
		fetchCollection('ddWebsite', setPageEntries);
	}, []);

	if (pageEntries === null) {
		return null;
	}
	const getPageData = (page: string) => {
		return pageEntries.find((item: PageEntry) => item.pageId === page);
	}

	const homeData = getPageData('home');
	const webPresenceData = getPageData('webpresence');
	const myWorkData = getPageData('work');
	const myWorkDetailData = getPageData('My Work Details');
	return (
		<Router>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path="/" element={<HomePage {...homeData} />} />
					<Route path="/web-presence" element={<WebPresencePage {...webPresenceData} />} />
					<Route path="/my-work" element={<MyWorkPage {...myWorkData} />} />
					<Route path="/work-details/:pageid" element={<MyWorkDetail />} />
				</Routes>
			</Suspense>
		</Router>
	)
}

export default App
