const urlPrefix = window.location.hostname === 'localhost' ? 'http://localhost:8888' : '';

const fetchCollection = async function (contentType: string, callback: any) {
    const apiUrl = `${urlPrefix}/content-type/${contentType}`;
    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        callback(data);
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}

export const fetchEntry = async function (entryId: string, contentType: string, callback: any) {
    const apiUrl = `${urlPrefix}/entry/${contentType}/${entryId}`;
    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        callback(data);
    } catch (error) {
        console.log('Failed to fetch data:', error);
    }
}

export default fetchCollection;
